import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogOutModal from "./LogOutModal";
import EditPasswordModal from "./EditPasswordModal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { sidebarcollpase } from "../redux/actions/user";
import { IoMdArrowDropup } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { useHasPermissionInAnyFleet } from "../customHooks/PermissionHooks";
import { useTheme } from "../context/ThemeContext";
import SettingsModal from "./SettingsModal";

function DashboardSideBar(props) {
  const emailId = localStorage.getItem("useremail");
  const firstName = localStorage.getItem("firstname");
  const lastName = localStorage.getItem("lastname");
  const userName = localStorage.getItem("userName");
  const [responsive, setresponsive] = useState();
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const canEditFleet = useHasPermissionInAnyFleet("fleet-view");
  const canEditRobot = useHasPermissionInAnyFleet("robot-management");
  const canSeeAnalytics = useHasPermissionInAnyFleet("fleet-analytics");
  const canManageUser = useHasPermissionInAnyFleet("user-management");
  const canMannageLocations = useHasPermissionInAnyFleet("locations");
  const theme = useTheme().themeMode;

  const dispatch = useDispatch();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setresponsive(window.screen.width);
  }, [responsive]);

  const { sidebarcollapse } = useSelector((state) => state.login);

  const handleSidebarCollapse = () => {
    if (sidebarcollapse) {
      dispatch(sidebarcollpase(false));
    } else {
      dispatch(sidebarcollpase(true));
    }
  };

  useEffect(() => {
    if(props?.smallScreen && sidebarcollapse){
      dispatch(sidebarcollpase(false));
    }
  })

  return (
    <>
      <div
        className={
          sidebarcollapse
            ? "Dashboard_side_bar_wrapper_main_short"
            : "Dashboard_side_bar_wrapper_main_long"
        }
      >
        <ul
          className={
            sidebarcollapse
              ? "Dashboard_page_side_bar_close"
              : " Dashboard_page_side_bar"
          }
        >
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links hidden midLg:block"
          >
            <li className={props.active === "" ? "SideBar_active" : null}>
              <img
                style={{ width: "100%" }}
                src={theme==='K-Light'?"/assets/images/OttonomyLogo-black.svg":"/assets/images/OttonomyLogo.png"}
                alt="logo"
              />
            </li>
          </Link>
          <Link
          onClick={() => {
            if(props?.setActiveTab){
              props?.setActiveTab('dashboard');
            }
          }}
            to="/dashboard"
            style={{ textDecoration: "none" }}
            className="Dashboard_side_bar_links"
          >
            <li
              className={props.active === "dashboard" ? "SideBar_active" : null}
            >
              <span />
              <p
                style={{ marginBottom: "0px" }}
                className="Dashboard_side_bar_P"
              >
                Dashboard
              </p>
            </li>
          </Link>
          {canEditFleet && (
            <Link
            onClick={() => {
              if(props?.setActiveTab){
                props?.setActiveTab('fleetpage');
              }
            }}
              to="/fleetPage"
              style={{ textDecoration: "none" }}
              className="Dashboard_side_bar_links"
            >
              <li
                className={
                  props.active === "fleetpage" ? "SideBar_active" : null
                }
              >
                <span style={{ width: "25%" }} />
                <p
                  style={{ marginBottom: "0px" }}
                  className="Dashboard_side_bar_P"
                >
                  Fleets
                </p>
              </li>
            </Link>
          )}
          {canEditRobot && (
            <Link
            onClick={() => {
              if(props?.setActiveTab){
                props?.setActiveTab('robotpage');
              }
            }}
              to="/robotpage"
              style={{ textDecoration: "none" }}
              className="Dashboard_side_bar_links"
            >
              <li
                className={
                  props.active === "robotpage" ? "SideBar_active" : null
                }
              >
                <span style={{ width: "25%" }} />
                <p
                  style={{ marginBottom: "0px" }}
                  className="Dashboard_side_bar_P"
                >
                  Robots
                </p>
              </li>
            </Link>
          )}
          {canMannageLocations && (
            <Link
              to="/locations"
              onClick={() => {
                if(props?.setActiveTab){
                  props?.setActiveTab('locations');
                }
              }}
              style={{ textDecoration: "none" }}
              className="Dashboard_side_bar_links"
            >
              <li
                className={
                  props.active === "locations" ? "SideBar_active" : null
                }
              >
                <span style={{ width: "25%" }} />
                <p
                  style={{ marginBottom: "0px" }}
                  className="Dashboard_side_bar_P"
                >
                  Locations
                </p>
              </li>
            </Link>
          )}
          {canSeeAnalytics && (
            <Link
              to="/analytics"
              onClick={() => {
                if(props?.setActiveTab){
                  props?.setActiveTab('analytics');
                }
              }}
              style={{ textDecoration: "none" }}
              className="Dashboard_side_bar_links"
            >
              <li
                className={
                  props.active === "analytics" ? "SideBar_active" : null
                }
              >
                <span style={{ width: "25%" }} />
                <p
                  style={{ marginBottom: "0px" }}
                  className="Dashboard_side_bar_P"
                >
                  Analytics
                </p>
              </li>
            </Link>
          )}
          {canManageUser && (
            <Link
              to="/user-management"
              onClick={() => {
                if(props?.setActiveTab){
                  props?.setActiveTab('user-management');
                }
              }}
              style={{ textDecoration: "none" }}
              className="Dashboard_side_bar_links"
            >
              <li
                className={
                  props.active === "user-management" ? "SideBar_active" : null
                }
              >
                <span style={{ width: "25%" }} />
                <p
                  style={{ marginBottom: "0px" }}
                  className="Dashboard_side_bar_P"
                >
                  User Management
                </p>
              </li>
            </Link>
          )}
        </ul>

        <div
          className={"shadow-md "+
           ( sidebarcollapse
              ? "name-log_collapse"
              : "bg-darkBlue p-4 rounded-lg flex flex-col space-y-2")
          }
        >
          <div className="flex text-center items-center justify-between">
            <p className="text-textColor text-sm overflow-hidden text-ellipsis">
              {firstName !== "undefined" || lastName !== "undefined" ? (
                <>
                  {firstName} {lastName}
                </>
              ) : userName !== "undefined" ? (
                <>{userName}</>
              ) : (
                <>{emailId}</>
              )}{" "}
            </p>
            <div ref={dropdownRef} className="relative inline-block text-left">
              <button
                className="flex justify-between text-xl !text-secTextColor"
                onClick={toggleDropdown}
              >
                {" "}
                {isOpen ? <IoMdArrowDropdown color="var(--textColor)" /> : <IoMdArrowDropup color="var(--textColor)"/>}
              </button>
              {isOpen && (
                <div className="absolute bottom-8 right-0 w-36 rounded shadow bg-darkBlue">
                  <div className="flex justify-between flex-wrap">
                    <button
                      className="w-full text-xs text-left text-textColor p-3 font-normal hover:bg-primaryColor"
                      onClick={() => {
                        setIsOpen(false);
                        setOpenLogoutModal(true);
                      }}
                    >
                      Logout
                    </button>
                    <button
                      className="w-full text-xs text-left text-textColor p-3 font-normal hover:bg-primaryColor"
                      onClick={() => {
                        setIsOpen(false);
                        setOpenChangePasswordModal(true);
                      }}
                    >
                      Change Password
                    </button>
                    <button
                      className="w-full text-xs text-left text-textColor p-3 font-normal hover:bg-primaryColor"
                      onClick={() => {
                        setIsOpen(false);
                        setOpenSettingsModal(true);
                      }}
                    >
                      Settings
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {openLogoutModal && (
            <LogOutModal onlyModal={true} className="hover:bg-ottonomyBlue" />
          )}
          <EditPasswordModal
            isOpen={openChangePasswordModal}
            onClose={() => {
              setOpenChangePasswordModal(false);
            }}
          />

          {
            openSettingsModal && <SettingsModal isOpen={openSettingsModal}
            onClose={() => {
              setOpenSettingsModal(false);
            }}/>
          }
        </div>
        <div className="SideBar_collapse_div hidden midLg:flex">
          {sidebarcollapse ? (
            <ArrowForwardIosIcon
              className="SideBar_collapse_div_svg_one"
              onClick={handleSidebarCollapse}
            />
          ) : (
            <ArrowBackIosIcon
              className="SideBar_collapse_div_svg_one"
              onClick={handleSidebarCollapse}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardSideBar;
