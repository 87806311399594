import { Modal, Box, Button, useMediaQuery } from "@mui/material";
import { deleteLocation } from "../API";
import { toast } from "react-toastify";
import { useState } from "react";
import Loader from "./Loader";

const deleteLocationModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "var(--primaryColor)",
  border: "none",
  outline: "none",
  boxShadow: 24,
  borderRadius: "8px",
};

const DeleteLocationModal = ({
  locationDetails,
  deleteLocationModalOpen,
  setDeleteLocationModalOpen,
  fetchLocationsByType,
  setLocationsList
}) => {
  const [loader, setLoader] = useState(false);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleLocationDelete = (locationDetails) => {
    deleteLocation(locationDetails)
      .then((res) => {
        setLoader(false);
        setDeleteLocationModalOpen(false);
        toast.success("Deleted Location successfully!");
        fetchLocationsByType()
        .then((result) => {
          if (result.status === 200) {
            setLocationsList(result?.data?.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      })
      .catch((err) => {
        setLoader(false);
        setDeleteLocationModalOpen(false);
        console.error(err);
        toast.error(err.message)
        // toast.error("Something went wrong while deleting location!");
      });

    setLoader(true);
  };

  return (
    <Modal
      open={deleteLocationModalOpen}
      //   onHide={() => setEditLocationModalOpen(false)}
      backdrop="static"
      keyboard="false"
    >
      <Box
        sx={{
          ...deleteLocationModalStyle,
          width: isMobile ? "100%" : 500,
          height: isMobile ? "100%" : "auto",
        }}
      >
        {loader ? (
          <div className="flex flex-col items-center justify-center p-4">
            <Loader />
            <span className="text-textColor">Deleting Location...</span>
          </div>
        ) : (
          <div className="p-4 Cancel-order-Modal-Mainwrapper">
            <p> Are you sure want to remove this location ?</p>
            <div className="Modal-button-Main-wrapper">
              <Button
                className="Modal-button-Main-btn yes-red-active !text-secTextColor"
                onClick={() => {
                  handleLocationDelete(locationDetails);
                }}
              >
                {" "}
                Yes
              </Button>
              <Button
              sx={{ color: "var(--textColor)", border: "1px solid var(--textColor)" }}
                className="Modal-button-Main-btn"
                onClick={() => setDeleteLocationModalOpen(false)}
              >
                {" "}
                No
              </Button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default DeleteLocationModal;
