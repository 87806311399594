import { Fragment, useEffect, useState } from "react";
import { createMission, updateMission } from "../API";
import { Field, useFormik } from "formik";

import { useMediaQuery } from "@mui/material";
import { FormControl, TextField, Autocomplete } from "@mui/material";

import Loader from "./Loader";
import * as yup from "yup";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";

function AddMissionsModal({ modalOpen, setModalOpen, locationList, mission }) {
  const [loader, setLoader] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const token = localStorage.getItem("token");
  const [missionId, setMissionId] = useState(mission ? mission?._id : null);
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));

  // const [missionId, setMissionId] = useState(mission._id);
  const toastmsg = (msg) => toast(msg);

  // const validationSchema = Yup.object({
  //     name: Yup.string().required('Name is required'),
  //     actions: Yup.array().of(
  //       Yup.object({
  //         name: Yup.string().required('Action name is required'),
  //         id: Yup.string().required('Action ID is required'),
  //         timestamp: Yup.string().required('Timestamp is required'), // Add timestamp validation
  //       })
  //     ),
  //   });

  const actionBody = {
    actionType: "",
    message: "",
    locationId: null,
    startTime: "",
    scheduled: true,
    finishTimeCalculated: "",
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      actions: [actionBody],
      status: true,
      isDeleted: false,
      fleetId: fleetData.fleetId,
      autoStart: false,
      type: "scheduled",
      delayBefore: 0,
      delayAfter: 0,
      recurrance: {
        status: true,
        recurranceType: "DAILY",
      },
    },
    onSubmit: (values, { resetForm }) => {
      let isValidated = true;

      values.actions.map((item) => {
        if (isValidated && item.startTime) {
          let hour = "00",
            min = `00`;
          hour = item.startTime.substring(0, 2);
          min = item.startTime.substring(2, 4);
          if (
            item.startTime.length > 4 ||
            parseInt(hour) > 23 ||
            parseInt(hour) < 0 ||
            parseInt(min) > 59 ||
            parseInt(min) < 0
          ) {
            isValidated = false;
            console.log("first valid not");
            return;
          }
        }
        if (isValidated && item.finishTimeCalculated) {
          let hour = "00",
            min = `00`;
          hour = item.finishTimeCalculated.substring(0, 2);
          min = item.finishTimeCalculated.substring(2, 4);
          if (
            item.finishTimeCalculated.length > 4 ||
            parseInt(hour) > 23 ||
            parseInt(hour) < 0 ||
            parseInt(min) > 59 ||
            parseInt(min) < 0
          ) {
            console.log("second valid not");
            isValidated = false;
            return;
          }
        }

        if (
          isValidated &&
          parseInt(item.startTime) > parseInt(item.finishTimeCalculated)
        ) {
          console.log(
            parseInt(item.startTime),
            parseInt(item.finishTimeCalculated),
            "third valid not"
          );
          isValidated = false;
          return;
        }

        return;
      });
      if (!isValidated) {
        toast.error("Invalid time");
        console.log("validation failed");
        return;
      }
      if (mission) {
        updateMission(values, missionId, token).then((res) => {
          toast.success("Mission updated successfully");
          console.log(res, "res");
        });
      } else {
        createMission(values, token).then((res) => {
          toast.success("Mission created successfully");
          console.log(res, "res");
        });
      }

      resetForm({});
      setModalOpen(false);
    },
  });

  useEffect(() => {
    if (mission) {
      console.log(mission, "mission here?");
      formik.setValues(mission);
    }
  }, []);

  const modalstyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "var(--primaryColor)",
    border: "none",
    boxShadow: 24,
  };
  const actionTypes = ["Move And Stay","Move To Location","Deliver Orders","Move And Deliver", "EXPECTLOAD","Move To Home","AUTODOCK","CHARGING"];

  const handleActionTypeChange = (selectedOption, val, index) => {
    // val?.locationId?.locationName

    if (!selectedOption) {
      return;
    }

    if (selectedOption === "Move and Drop") {
      let loadCount = formik.values.actions.filter((item) => {
        return item.actionType === "Move and Pickup";
      }).length;

      if (loadCount < 1 || (loadCount === 1 && val.actionType === "Move and Pickup")) {
        toast.error("Please Add Load First");
        return;
      }
    }

    formik.setFieldValue(
      "actions",
      formik.values.actions.map((item, idx) => {
        if (index === idx) {
          return {
            ...item,
            actionType: selectedOption,
            message: selectedOption,
          };
        }
        return item;
      })
    );
  };

  const handleChange = (e, index, action, name) => {
    let val = { ...action };

    val[`${name}`] = e.target.value;

    formik.setFieldValue(
      "actions",
      formik.values?.actions.map((item, idx) => {
        if (idx === index) {
          return val;
        }
        return item;
      })
    );
  };

  const handleLocationChange = (selectedOption, val, index) => {
    let newVal = { ...val };

    // if (!selectedOption || newVal.message === "MOVEHOME") {
    //   return;
    // }

    newVal = {
      ...newVal,
      locationId: selectedOption,
    };

    formik.setFieldValue(
      "actions",
      formik.values?.actions.map((item, idx) => {
        if (index === idx) {
          return newVal;
        }
        return item;
      })
    );
  };

  return (
    <>
      <>
        <Modal
          open={modalOpen}
          onHide={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          backdrop="static"
          keyboard="false"
        >
          <Box
            sx={{
              ...modalstyle1,
              width: isMobile ? "100%" : "80%",
              height: isMobile ? "100%" : "auto",
              border: 0,
              borderRadius: "8px",
            }}
          >
            {loader ? (
              <div className="flex flex-col items-center justify-center p-4">
                <Loader />
              </div>
            ) : (
              <div
                className={`Order_modal_wrapper !text-secTextColor ${
                  isMobile
                    ? "h-full": "h-[92vh]"
                    }`}
              >
                <div className="p-4 d-flex-center OrderModal_heading_wrapper">
                  <h3 className="Order_modal_h3">
                    {mission ? "Edit" : "Add"} Mission
                  </h3>
                </div>
                <div className="h-[calc(100%-89px)]">
                  <form className="h-full" onSubmit={formik.handleSubmit}>
                    <div className=" overflow-scroll max-h-[calc(100%-132px)] py-2">
                      <div className="relative h-[52px]">
                        <label className=" text-sm absolute top-[-12px]  left-[38px] px-2 text-textColor bg-primaryColor">
                          Mission Name
                        </label>{" "}
                        <input
                          name="name"
                          className="mx-4 px-4 py-2 w-[calc(100%-50px)] text-2xl text-center border-[1px] border-textColor text-textColor rounded-md bg-bodycolor font-bold "
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                        />
                      </div>

                      <div className=" max-h-[calc(100%-116px)] overflow-scroll my-4">
                        {formik.values?.actions.map((action, index) => {
                          // if (
                          //   mission &&
                          //   !(
                          //     action?.actionType === "MOVELOCATION" ||
                          //     action?.actionType === ""
                          //   )
                          // ) {
                          //   return null;
                          // }

                          let val = action;
                          let isEditable = true;
                          if (action?.message === "MOVEHOME" || action?.message==="Move To Home") {
                            isEditable = false;
                          }

                          return (
                            // <div
                            //   className={`${
                            //     index === 0
                            //       ? "border-t-[1px] border-white mt-4"
                            //       : ""
                            //   }`}
                            // >
                            <div
                              className={` mx-4 my-2  overflow-x-scroll pt-4 pb-2 p-2`}
                            >
                              <div className="min-w-[1050px] grid grid-cols-15 gap-4 ">
                              <div
                                className="col-span-3 w-full rounded-md border-[1px] border-white"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "4px",
                                }}
                              >
                                <FormControl
                                  style={{
                                    backgroundColor: "#272e42",
                                    width: "100%",
                                    borderRadius: "0.375rem",
                                    borderColor: "white",
                                    WebkitTextFillColor:
                                      "var(--textColor) !important",
                                    position: "relative",
                                  }}
                                >
                                  <label className=" text-sm absolute top-[-12px] left-[12px] px-2 bg-primaryColor">
                                    Action Type
                                  </label>
                                  <Autocomplete
                                    style={{
                                      width: "100%",
                                    }}
                                    sx={{
                                        
                                        "& .MuiSvgIcon-root": {
                                          color: "var(--textColor) !important", // Color of the dropdown arrow
                                        },
                                    
                                      backgroundColor: "var(--bodycolor)",
                                      border: "1px solid var(--textColor)",
                                      borderRadius: "0.375rem",
                                    }}
                                    placeholder="Enter the Action Type"
                                    options={actionTypes}
                                    getOptionLabel={(option) => option}
                                    value={
                                      val?.message
                                        ? actionTypes.filter(
                                            (item) =>
                                              item === val?.actionType
                                          )[0]
                                        : null
                                    }
                                    onChange={(_, selectedOption) => {
                                      handleActionTypeChange(
                                        selectedOption,
                                        val,
                                        index
                                      );
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option === value
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          ...params.InputProps,
                                          style: {
                                            borderColor: "white",
                                            color: "var(--textColor) !important", // Text color
                                          },
                                          disableUnderline: true, // To remove the underline of the text field
                                        }}
                                        style={{
                                          color: "#000000",
                                          margin: "0",
                                          padding: "5px 5px 5px 10px",
                                          fontSize: "14px",
                                        }}
                                        sx={{
                                          "& .MuiAutocomplete-endAdornment": {
                                            "& .MuiSvgIcon-root": {
                                              color: "var(--textColor)", // Color of the dropdown arrow
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                              <div
                                className="col-span-3 w-[100%] rounded-md border-[1px] border-white"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "4px",
                                }}
                              >
                                <FormControl
                                  style={{
                                    backgroundColor: "#272e42",
                                    width: "100%",
                                    borderRadius: "0.375rem",
                                    borderColor: "white",
                                    position: "relative",
                                    WebkitTextFillColor:
                                      "var(--textColor) !important",
                                  }}
                                >
                                  <label className=" text-sm absolute top-[-12px] bg-primaryColor left-[12px] px-2">
                                    Location
                                  </label>

                                  <Autocomplete
                                    style={{
                                      width: "100%",
                                    }}
                                    sx={{
                                        
                                      "& .MuiSvgIcon-root": {
                                        color: "var(--textColor) !important", // Color of the dropdown arrow
                                      },
                                  
                                    backgroundColor: "var(--bodycolor)",
                                    border: "1px solid var(--textColor)",
                                    borderRadius: "0.375rem",
                                  }}
                                    openOnFocus={isEditable}
                                    disableClearable={!isEditable}
                                    options={locationList}
                                    getOptionLabel={(option) =>
                                      option.locationName
                                    }
                                    value={
                                      val?.locationId?._id === ""
                                        ? null
                                        : locationList.find((option) => {
                                            return (
                                              option?._id ===
                                              val?.locationId?._id
                                            );
                                          }) || null
                                    }
                                    onChange={(_, selectedOption) => {
                                      handleLocationChange(
                                        selectedOption,
                                        val,
                                        index
                                      );
                                    }}
                                    isOptionEqualToValue={(option, value) => {
                                      return (
                                        option?.locationName ===
                                        value?.locationName
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        disabled={!isEditable}
                                        InputProps={{
                                          ...params.InputProps,
                                          style: {
                                            borderColor: "white",
                                            color: "var(--textColor) !important", // Text color
                                          },
                                          disableUnderline: true, // To remove the underline of the text field
                                        }}
                                        style={{
                                          color: "#000000",
                                          margin: "0",
                                          padding: "5px 5px 5px 10px",
                                          fontSize: "14px",
                                        }}
                                        sx={{
                                          "& .MuiAutocomplete-endAdornment": {
                                            "& .MuiSvgIcon-root": {
                                              color: "white", // Color of the dropdown arrow
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                              <div className="col-span-2 w-full relative">
                                <label
                                  htmlFor="startTime"
                                  className=" text-sm absolute top-[-12px] bg-primaryColor text-textColor left-[12px] px-2"
                                >
                                  Start Time
                                </label>

                                <input
                                  name="startTime"
                                  className="px-2 h-full text-[16px] py-2.5 text-left border-[1px] w-full border-textColor rounded-md bg-bodycolor text-textColor"
                                  type="text"
                                  placeholder="e.g. 0745"
                                  value={val.startTime}
                                  onChange={(e) => {
                                    handleChange(
                                      e,
                                      index,
                                      action,
                                      "startTime"
                                    );
                                  }}
                                  maxLength="4"
                                  pattern="[0-9]*"
                                />
                              </div>
                              <div className="w-full col-span-2 relative">
                                <label
                                  htmlFor="finishTimeCalculated"
                                  className=" text-sm absolute top-[-12px] bg-primaryColor text-textColor left-[12px] px-2"
                                >
                                  End Time
                                </label>

                                <input
                                  name="finishTimeCalculated"
                                  className="px-2 h-full text-[16px] py-2.5 text-left border-[1px] w-full border-textColor rounded-md bg-bodycolor text-textColor"
                                  type="text"
                                  placeholder="e.g. 0745"
                                  value={val.finishTimeCalculated}
                                  onChange={(e) => {
                                    handleChange(
                                      e,
                                      index,
                                      action,
                                      "finishTimeCalculated"
                                    );
                                  }}
                                  maxLength="4"
                                  pattern="[0-9]*"
                                />
                              </div>
                              <div className="col-span-2 w-full relative">
                                <label
                                  htmlFor="delayBefore"
                                  className=" text-sm absolute top-[-12px] bg-primaryColor text-textColor left-[12px] px-2"
                                >
                                  Delay Before
                                </label>

                                <input
                                  name="delayBefore"
                                  className="px-2 h-full text-[16px] py-2.5 text-left border-[1px] w-full border-textColor rounded-md bg-bodycolor text-textColor"
                                  type="text"
                                  placeholder="in ms"
                                  value={val.delayBefore}
                                  onChange={(e) => {
                                    handleChange(
                                      e,
                                      index,
                                      action,
                                      "delayBefore"
                                    );
                                  }}
                                  pattern="[0-9]*"
                                />
                              </div>
                              <div className="col-span-2 w-full relative">
                                <label
                                  htmlFor="delayAfter"
                                  className=" text-sm absolute top-[-12px] bg-primaryColor text-textColor left-[12px] px-2"
                                >
                                  Delay After
                                </label>

                                <input
                                  name="delayAfter"
                                  className="px-2 h-full text-[16px] py-2.5 text-left border-[1px] w-full border-textColor rounded-md bg-bodycolor text-textColor"
                                  type="text"
                                  placeholder="in ms"
                                  value={val.delayAfter}
                                  onChange={(e) => {
                                    handleChange(
                                      e,
                                      index,
                                      action,
                                      "delayAfter"
                                    );
                                  }}
                                  pattern="[0-9]*"
                                />
                              </div>
                              <div
                                className="col-span-1 px-4 w-fit ml-auto mt-2"
                                onClick={() => {
                                  formik.setFieldValue(
                                    "actions",
                                    formik.values.actions.filter(
                                      (item, idx) => idx !== index
                                    )
                                  );
                                }}
                              >
                                <DeleteIcon className="w-5 h-5 cursor-pointer text-[#e96c67]" />
                              </div>
                              </div>
                            
                            </div>
                          );
                        })}
                      </div>

                      <div
                        className="mx-auto h-[34px] px-2 py-1 border-[1px] !border-textColor !text-textColor w-fit rounded-md my-2 hover:cursor-pointer"
                        onClick={() => {
                          formik.values.actions.push(actionBody);
                        }}
                      >
                        Add Action
                      </div>
                    </div>

                    <div className="absolute inset-x-0 bottom-0 flex justify-between px-3 Order_modal_button_wrapper">
                      <button
                        onClick={() => setModalOpen(false)}
                        className="!p-[10px_20px] m-[20px] font-bold text-textColor transition-all border-2 rounded-lg bg-ottonomyDarkBlue border-ottonomyBlue hover:bg-ottonomyBlue"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="transition-all Order_save_button hover:bg-ottonomyBlue !text-secTextColor"
                        onClick={() => {
                          formik.handleSubmit();
                          setModalOpen(false);
                        }}
                      >
                        Submit{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      </>
    </>
  );
}

export default AddMissionsModal;
