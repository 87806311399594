import axios from "axios";
import { useState, useEffect, useRef } from "react";
import ConfirmationModel from "./ConfirmationModel";
import RobotCard from "./RobotCard";
import { toast } from "react-toastify";
import Loader from "../components/Reusable/Loader";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import Dropdown from "../components/Reusable/Dropdown";
import { useHasPermissionInAnyFleet } from "../customHooks/PermissionHooks";

export default function SummonCard({ pickupLocations, fleetId, robotData }) {
  let hasPermissionForTestSummon = useHasPermissionInAnyFleet("test-summon");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [selectedLocation, setSeletedLocation] = useState(null);
  const [summonedRobots, setSummonedRobots] = useState([]);
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const hasPermissionForSummonStoreLocation = useHasPermissionInAnyFleet(
    "summon-store-location"
  );
  useEffect(() => {
    if (hasPermissionForSummonStoreLocation) {
      setSeletedLocation(pickupLocations[1]);
    } else {
      setSeletedLocation(pickupLocations[0]);
    }
  }, [pickupLocations, hasPermissionForSummonStoreLocation]);

  useEffect(() => {
    let summonedrobots = robotData.filter(
      (robot) =>
        robot?.commandQueue?.find(
          (command) =>
            command.message === "MOVESUMMON" &&
            command?.locationName === selectedLocation?.label
        ) ||
        (robot?.currentRobotAction?.message === "MOVESUMMON" &&
          robot?.currentRobotAction?.locationName ===
            selectedLocation?.label) ||
        robot?.nearestLocation === selectedLocation?.label
    );
    setSummonedRobots(summonedrobots);
  }, [robotData, selectedLocation]);
  const callSummon = async (summonActionLive) => {
    try {
      setLoading(true);
      let location = JSON.parse(selectedLocation.value);
      let summonLive = summonActionLive === "TEST" ? false : true;
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}fleet/summonToLocation`,
        {
          fleetId: fleetId,
          latitude: location.latitude,
          longitude: location.longitude,
          locationName: location.locationName,
          headingAngle: location.headingAngle,
          dockingName: location.dockingName,
          storeId: location.storeId,
          live: summonLive,
        }
      );
      if (response.status === 200) {
        setLoading(false);
        toast.success("Comand Sent");
      } else {
        setLoading(false);
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };
  const handleOpenModal = (message, confirmCallback) => {
    setModalText(message);
    setConfirmAction(() => confirmCallback);
    setShowConfirmationModal(true);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {loading && <Loader />}
      {summonedRobots?.length ? (
        <div className="relative flex flex-col gap-2 h-full overflow-auto ">
          <span className="sticky top-0 pb-2 flex items-center text-lg font-semibold font-poppins text-textColor">
            Ottobots in Queue : {selectedLocation?.label}
          </span>
          <div className="h-full relative flex flex-col gap-2 overflow-auto">
            {summonedRobots.map((robot, index) => (
              <RobotCard
                key={index}
                index={index}
                robot={robot}
                selectedLocation={selectedLocation}
                showSummonControls={true}
              />
            ))}
          </div>
          <div
            className="w-full h-16 px-8 py-4 bg-ottonomyBlue rounded-md self-center justify-center items-center gap-4 inline-flex cursor-pointer"
            onClick={() =>
              handleOpenModal(
                "Are you sure you want to summon an Ottobot?",
                callSummon
              )
            }
          >
            <img
              src="/assets/images/summon.svg"
              height={30}
              width={30}
              alt="summonIcon"
            />
            <div className="!text-secTextColor text-base font-bold font-['Poppins']">
              Summon Ottobot
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <span className="pb-2 text-lg font-semibold font-poppins self-start">
            {selectedLocation?.label}
          </span>
          <div className="flex w-full h-full flex-col items-center justify-center gap-12">
            <h2 className="relative text-[#8C8C8C] text-lg text-center font-bold  ">
              Press the Summon Button to call an Ottobot
            </h2>
            <div
              className="w-60 h-16 px-8 py-4 bg-ottonomyBlue rounded-md justify-center items-center gap-4 inline-flex cursor-pointer"
              onClick={() =>
                handleOpenModal(
                  "Are you sure you want to summon an Ottobot?",
                  callSummon
                )
              }
            >
              <img
                src="/assets/images/summon.svg"
                height={30}
                width={30}
                alt="summonIcon"
              />
              <div className="!text-secTextColor text-xl font-bold">Summon</div>
            </div>
          </div>
        </div>
      )}
      <ConfirmationModel
        showActionLiveDropdown={hasPermissionForTestSummon}
        textToDisplay={modalText}
        onConfirm={confirmAction}
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
      />
    </>
  );
}
