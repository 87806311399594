import { Autocomplete, FormControl, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { HiOutlinePhone } from "react-icons/hi";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { toast } from "react-toastify";

const TransitDetails = ({
  orderIndex,
  orderlistData,
  order,
  editCancellationTime,
  // editOrderCancellationTime,
  // changeOrderCancTime,
  updatedCancellationTime,
  handleCancellationInputChange,
  setUpdatedCancellationTime,
  setEditCancellationTime,
  setDeletedTransitOrderId,
  setOpenRemoveTransitOrderModal,
  dropLocationOptions,
  pickupLocationOptions,
  confirmEditLocationModal,
  setConfirmEditLocationModal,
  confirmEditDropLocationModal,
  setConfirmEditDropLocationModal,
  setSelectedLocation,
  setSelectedOrderId,
  isPickupLocationEditedToggle,
  setIsPickupLocationEditedToggle,
  isDropLocationEditedToggle,
  setIsDropLocationEditedToggle,
  fleetData,
  editPreparationTime,
  setConfirmationCancelOrder,
  changeOrderCancTime,
  setSelectedCancelOrderId,
}) => {
  const [isPickupLocationEdited, setIsPickupLocationEdited] = useState(false);
  const [isDropLocationEdited, setIsDropLocationEdited] = useState(false);

  const token = localStorage.getItem("token");

  const editOrderCancellationTime = (id) => {
    setUpdatedCancellationTime("");
    setEditCancellationTime(id);
  };

  const locationPositionValuePriority = (position) => {
    if (position && position.locationName) {
      return position.locationName;
    }

    if (position && position.latitude) {
      return `${position.latitude}, ${position.longitude}`;
    }

    if (position && position.positionX) {
      return `${position.positionX}, ${position.positionY}`;
    }
  };

  const orderStatusColor = (orderStatus, statusCode) => {
    switch (orderStatus.toLowerCase()) {
      case "order delivered":
        return (
          <p className="capitalize p-color-green orderStatus_p">
            {orderStatus} {statusCode && <>({statusCode})</>}
          </p>
        );
      case "order removed":
        return (
          <p className="capitalize p-color-red orderStatus_p">
            {orderStatus}
            {statusCode && <>({statusCode})</>}
          </p>
        );
      case "order not confirmed":
        return (
          <p className="capitalize p-color-yellow orderStatus_p">
            {orderStatus}
            {statusCode && <>({statusCode})</>}
          </p>
        );
      default:
        return (
          <p className="capitalize text-ottonomyBlue text-[13px] orderStatus_p">
            {orderStatus} {statusCode != null && <>({statusCode})</>}
          </p>
        );
    }
  };

  return (
    <tr
      className={`text-textColor font-light ${
        orderIndex !== orderlistData.length - 1 &&
        "border-b border-[rgb(255,255,255,0.16)]"
      }`}
    >
      <td className="p-3 ">
        {fleetData?.robotsDetails?.find(
          (robot) => robot.robotId === order.robotId
        )?.displayRobotName
          ? fleetData?.robotsDetails?.find(
              (robot) => robot.robotId === order.robotId
            )?.displayRobotName
          : order.robotId}
      </td>
      <td className="p-3">{order.orderId}</td>
      <td className="p-3 flex flex-col">
        {order?.orderInfo &&
          order?.orderInfo?.analyticsdata &&
          Object.entries(order?.orderInfo?.analyticsdata).map(
            ([key, value]) => (
              <div>
                {key === "numberoforder" ? (
                  <span>Number of Orders</span>
                ) : key === "numberofbottles" ? (
                  <span>Number of Items</span>
                ) : (
                  key
                )}{" "}
                : <span>{value}</span>
              </div>
            )
          )}
      </td>
      <td className="p-3 font-semibold">
        <div className="flex gap-1">
          <div className="flex flex-col">
            {orderStatusColor(order.orderStatus, order.statusCode)}
            <span className="text-xs !text-[10px]">
              {order.statusCode === 4 &&
                order.pick_open_door_received &&
                "PIN Entered"}
              {order.statusCode === 8 &&
                order.drop_open_door_received &&
                "PIN Entered"}
            </span>
          </div>

          {((order.noShowFlagPickup === 1 && order.statusCode === 4) ||
            (order.noShowFlagDelivery === 1 && order.statusCode === 8)) && (
            <div className="flex items-center gap-1">
              {editCancellationTime !== order.orderId && (
                <>
                  <TbEdit
                    className="w-5 h-5 cursor-pointer text-ottonomyBlue"
                    onClick={() =>
                      editOrderCancellationTime(order.orderId, true)
                    }
                  />
                  <IoIosCloseCircleOutline
                    className="w-5 h-5 text-red-500 cursor-pointer"
                    onClick={() => {
                      setSelectedCancelOrderId(order.orderId);
                      setConfirmationCancelOrder(true);
                      // changeOrderCancTime(order.orderId, true)
                    }}
                  />
                </>
              )}
              {editCancellationTime === order.orderId && (
                <div className="flex flex-col items-center justify-center h-full gap-1">
                  <div className="flex items-center justify-start w-full h-2 px-1">
                    <span className="text-[9px] ">Min - 3</span>
                  </div>
                  <div className="flex items-center gap-0.5 grow-0 shrink basis-auto ">
                    <span className="flex items-center justify-center h-full text-sm font-bold bg-transparent border rounded-md">
                      <div className="flex items-center w-16 h-5 px-2">
                        <input
                          type="number"
                          value={updatedCancellationTime}
                          onChange={(event) => {
                            if (!/^\d{0,2}$/.test(event.target.value)) {
                              return false;
                            } else {
                              setUpdatedCancellationTime(event.target.value);
                            }
                          }}
                          className="w-full h-full bg-transparent focus:outline-none"
                        />
                        <span className="text-xs text-textColor font-poppins">
                          min
                        </span>
                      </div>
                    </span>
                    <span
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => {
                        if (!isNaN(Number(updatedCancellationTime))) {
                          if (
                            Number(updatedCancellationTime) >= 3 &&
                            Number(updatedCancellationTime) <= 60
                          ) {
                            changeOrderCancTime(order.orderId, false);
                          } else {
                            toast.error(
                              "The timer value should be between 3 seconds to 60 seconds!!"
                            );
                          }
                        }
                      }}
                    >
                      <IoIosCheckmarkCircleOutline className="w-full h-full text-ottonomyBlue" />
                    </span>
                    <span
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => {
                        setUpdatedCancellationTime("");
                        setEditCancellationTime(null);
                      }}
                    >
                      <IoIosCloseCircleOutline className="w-full h-full text-[#E96C67]" />
                    </span>
                  </div>
                  <div className="flex items-center justify-end w-full h-2 px-9">
                    <span className="text-[9px]">Max - 60</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </td>
      <td className="p-3">{order.cabinId}</td>
      {fleetData?.booking?.pickup &&
        fleetData?.booking?.pickup.length !== 0 && (
          <td className="p-3 edit__location">
            {isPickupLocationEdited && isPickupLocationEditedToggle ? (
              <div className="flex flex-row items-center gap-1">
                <FormControl
                  className="bg-[rgb(28,30,43)] text-[rgb(189,196,224)]"
                  sx={{
                    fontSize: "10px",
                    borderRadius: "8px",
                    backgroundColor: "transparent",
                  }}
                >
                  <Autocomplete
                    options={pickupLocationOptions}
                    clearOnBlur={false}
                    value={
                      order?.pickup?.locationName && order?.pickup?.locationName
                    }
                    sx={{
                      fontSize: "10px",
                      color: "white",
                    }}
                    onChange={(_, selectedOption) => {
                      setConfirmEditLocationModal(true);
                      setSelectedLocation(selectedOption);
                      setSelectedOrderId(order.orderId);
                      // console.log(_);
                      // console.log(selectedOption, "selectedOption");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          // style: {fontSize: 12}
                        }}
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "5px 5px 5px 10px",
                          minWidth: "250px",
                        }}
                        sx={{
                          fontSize: "10px!important",
                        }}
                      />
                    )}
                  />
                </FormControl>
                <span className="w-5 h-5 cursor-pointer">
                  <IoIosCloseCircleOutline
                    className="w-full h-full text-[#E96C67]"
                    onClick={() => {
                      setIsPickupLocationEdited(false);
                      setIsPickupLocationEditedToggle(false);
                    }}
                  />
                </span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <span>{locationPositionValuePriority(order.pickup)}</span>
                {order.statusCode < 5 && (
                  <button
                    className="ml-2 transition-all rounded-sm"
                    onClick={() => {
                      setIsPickupLocationEdited(true);
                      setIsPickupLocationEditedToggle(true);
                    }}
                  >
                    <TbEdit className="w-5 h-5 cursor-pointer text-ottonomyBlue" />
                    {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 20.652 20.652"
                  className="self-center"
                >
                  <g
                    id="noun-edit-1150515"
                    transform="translate(-10.848 -10.849)"
                  >
                    <path
                      id="Path_250"
                      data-name="Path 250"
                      d="M31.307,15.7l-4.662-4.662a.659.659,0,0,0-.932,0L22.9,13.854a.657.657,0,0,0-.193.466.656.656,0,0,0,.193.466l4.2,4.2-7.88,7.879L15.5,23.144l7.02-6.951a.659.659,0,1,0-.928-.937l-7.4,7.326a.668.668,0,0,0-.1.085.654.654,0,0,0-.077.094l-1.786,1.768a.656.656,0,0,0-.182.338l-1.181,5.844a.659.659,0,0,0,.646.79.643.643,0,0,0,.131-.013l5.843-1.181a.655.655,0,0,0,.335-.18l13.49-13.49a.659.659,0,0,0,0-.932ZM17.026,29.053,12.351,30l.944-4.673,1.265-1.253L18.284,27.8Zm11-11-3.73-3.73,1.881-1.881,3.73,3.73Z"
                      fill="#02b7d4"
                    />
                  </g>
                </svg> */}
                  </button>
                )}
              </div>
            )}
          </td>
        )}
      {fleetData?.booking?.delivery &&
        fleetData?.booking?.delivery.length !== 0 && (
          <td className="p-3 edit__location">
            {isDropLocationEdited && isDropLocationEditedToggle ? (
              <div className="flex flex-row items-center gap-1">
                <FormControl
                  className="bg-[rgb(28,30,43)] text-[rgb(189,196,224)]"
                  sx={{
                    fontSize: "10px",
                    borderRadius: "8px",
                    backgroundColor: "transparent",
                  }}
                >
                  <Autocomplete
                    options={dropLocationOptions}
                    clearOnBlur={false}
                    value={
                      order?.delivery?.locationName &&
                      order?.delivery?.locationName
                    }
                    sx={{
                      fontSize: "10px",
                      color: "white",
                    }}
                    onChange={(_, selectedOption) => {
                      setConfirmEditDropLocationModal(true);
                      setSelectedLocation(selectedOption);
                      setSelectedOrderId(order.orderId);
                      // console.log(_);
                      // console.log(selectedOption, "selectedOption");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                          // style: {fontSize: 12}
                        }}
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "5px 5px 5px 10px",
                          minWidth: "250px",
                        }}
                        sx={{
                          fontSize: "10px!important",
                        }}
                      />
                    )}
                  />
                </FormControl>
                <span className="w-5 h-5 cursor-pointer">
                  <IoIosCloseCircleOutline
                    className="w-full h-full text-[#E96C67]"
                    onClick={() => {
                      setIsDropLocationEdited(false);
                      setIsDropLocationEditedToggle(false);
                    }}
                  />
                </span>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <span>{locationPositionValuePriority(order.delivery)}</span>
                {order.statusCode < 9 && (
                  <button
                    className="ml-2 transition-all rounded-sm"
                    onClick={() => {
                      setIsDropLocationEdited(true);
                      setIsDropLocationEditedToggle(true);
                    }}
                  >
                    <TbEdit className="w-5 h-5 cursor-pointer text-ottonomyBlue" />
                    {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 20.652 20.652"
                  className="self-center"
                >
                  <g
                    id="noun-edit-1150515"
                    transform="translate(-10.848 -10.849)"
                  >
                    <path
                      id="Path_250"
                      data-name="Path 250"
                      d="M31.307,15.7l-4.662-4.662a.659.659,0,0,0-.932,0L22.9,13.854a.657.657,0,0,0-.193.466.656.656,0,0,0,.193.466l4.2,4.2-7.88,7.879L15.5,23.144l7.02-6.951a.659.659,0,1,0-.928-.937l-7.4,7.326a.668.668,0,0,0-.1.085.654.654,0,0,0-.077.094l-1.786,1.768a.656.656,0,0,0-.182.338l-1.181,5.844a.659.659,0,0,0,.646.79.643.643,0,0,0,.131-.013l5.843-1.181a.655.655,0,0,0,.335-.18l13.49-13.49a.659.659,0,0,0,0-.932ZM17.026,29.053,12.351,30l.944-4.673,1.265-1.253L18.284,27.8Zm11-11-3.73-3.73,1.881-1.881,3.73,3.73Z"
                      fill="#02b7d4"
                    />
                  </g>
                </svg> */}
                  </button>
                )}
              </div>
            )}
          </td>
        )}
      <td className="p-3">{order?.pickPin}</td>
      <td className="p-3">{order?.dropPin}</td>
      <td className="p-3">
        {new Date(order.createdAt).toLocaleString("en-US", {
          timeZone: fleetData?.map?.timeZone ?? "America/New_York",
          timeZoneName: "short",
        })}
      </td>
      <td className="p-3">
        <div className="flex items-center gap-2">
          {order?.phone ? (
            <a
              href={`tel:+${order.phone}`}
              className="!text-textColor no-underline flex gap-1.5 px-2.5 py-1.5 cursor-pointer item-center rounded-lg bg-ottonomyBlue"
              title={`+${order.phone}`}
            >
              <HiOutlinePhone className="w-4 h-auto !text-secTextColor" />
              <span className="text-sm font-semibold !text-secTextColor">Call</span>
            </a>
          ) : null}

          {order.statusCode < 9 && (
            <div
              className="flex gap-1 rounded-lg cursor-pointer text-sm py-1.5 px-2.5 border-[1px] border-[#E96C67]"
              onClick={() => {
                setDeletedTransitOrderId(order.orderId);
                setOpenRemoveTransitOrderModal(true);
                // deleteOrderTransit(item.orderId)
              }}
            >
              <RiDeleteBin6Line className="w-5 h-5 text-[#E96C67]" />
              <span className="text-sm font-semibold text-[#E96C67]">
                Cancel
              </span>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default TransitDetails;
