import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import OTPVerification from "./components/OTPVerification";
import IndividualFleet from "./subcomponents/IndividualFleet";
import RobotIndividual from "./subcomponents/RobotIndividual";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import IndividualUsers from "./subcomponents/IndividualUsers";
import LogOutModal from "./subcomponents/LogOutModal";
import Dashboard from "./components/Dashboard";
import RobotPage from "./subcomponents/RobotPage";
import EditFleet from "./subcomponents/EditFleet";
import FleetPage from "./subcomponents/FleetPage";
import OrderList from "./subcomponents/OrderList";
import EditRobot from "./subcomponents/EditRobot";
import EditUser from "./subcomponents/EditUser";
import AddOrder from "./subcomponents/AddOrder";
import UserList from "./subcomponents/UserList";
import AddFleet from "./subcomponents/AddFleet";
import AddRobot from "./subcomponents/AddRobot";
import AddUser from "./subcomponents/AddUser";
import Login from "./components/Login";
import Locations from "./components/Map/Locations";
import UserManagement from "./components/UserManagment";
import OrdersReports from "./subcomponents/OrdersReports";
import Unauthorized from "./components/Unauthorized";
import { fetchUserPermissions } from "./redux/slice/permissionSlice";
// import ProtectedRoute from "./components/Routes/ProtectedRoute";
// import "./App.css";
import './index.css'
import MissionReports from "./subcomponents/MissionReports";
import Analytics from "./subcomponents/Analytics";
import DashboardSideBar from "./subcomponents/DashboardSideBar";
import { themes } from "./Data/ThemeData";
import { useTheme } from "./context/ThemeContext";
function App() {
  const dashboardopen = true;
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [activeTab, setActiveTab] = useState("dashboard");
  const currentlocation = useLocation();
  const firstName = localStorage.getItem("firstname");
  const lastName = localStorage.getItem("lastname");
  const emailId = localStorage.getItem("useremail");
  const [currentcomponent, setcurrentcomponent] = useState("");
  const { userPermission } = useSelector((state) => state.permissionReducer);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const theme = searchParams.get('theme');
  const {switchTheme} = useTheme();

  useEffect(() => {
    console.log(theme, 'theme');
    if(theme && themes.includes(theme)){
      switchTheme(theme);
    }
  },[theme])
  useEffect(() => {
    if (!userPermission?.length) {
      dispatch(fetchUserPermissions());
    }
  }, [dispatch, userPermission]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const navigate = useNavigate();
  useEffect(() => {
    setcurrentcomponent(currentlocation.pathname);
  }, [currentlocation.pathname]);

  return (
    <div className="App min-w-[374px] overflow-x-scroll">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={<Dashboard dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />

        <Route
          path="/robotpage"
          element={<RobotPage dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab} />}
        />
        <Route
          path="/locations"
          element={
            // <ProtectedRoute requiredPermission="locations">
            <Locations dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab} />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/analytics"
          element={
            // <ProtectedRoute requiredPermission="fleet-analytics">
            <Analytics dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab} />
            // <MissionReports dashboardopen={dashboardopen} />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/fleetPage"
          element={
            // <ProtectedRoute requiredPermission="fleet-view">
            <FleetPage dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/user-management"
          element={
            // <ProtectedRoute requiredPermission="user-management">
            <UserManagement dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/addfleet"
          element={
            // <ProtectedRoute requiredPermission="fleet-management">
            <AddFleet dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/addrobot"
          element={
            // <ProtectedRoute requiredPermission="robot-management">
            <AddRobot dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/adduser"
          element={
            // <ProtectedRoute requiredPermission="user-management">
            <AddUser dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>
            // </ProtectedRoute>
          }
        />
        <Route
          path="robotPage/:robotId"
          element={
            // <ProtectedRoute requiredPermission="robot-management">
            <RobotIndividual dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/userlist"
          element={<UserList dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route
          path="/addorder"
          element={<AddOrder dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route
          path="/editfleet"
          element={<EditFleet dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route
          path="/editfleet/:fleetId"
          element={<EditFleet dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route
          path="/editrobot"
          element={<EditRobot dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route
          path="/edituser"
          element={<EditUser dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route
          path="/otpverification"
          element={<OTPVerification dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route
          path="/orderlist"
          element={<OrderList dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route
          path="fleetPage/:fleetId"
          element={<IndividualFleet dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />

        <Route
          path="editrobot/:robotId"
          element={<EditRobot dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route
          path="userlist/:userId"
          element={<IndividualUsers dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route
          path="edituser/:userid"
          element={<EditUser dashboardopen={dashboardopen} activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </div>
  );
}

export default App;
